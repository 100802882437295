<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        disable-sort
        class="mb-3 title" 
        hide-default-footer
        loader-height="2"
        :headers="headers"
        :loading="loading"
        :items="suppliers.data">
      </v-data-table>
      <app-pagination
        v-if="suppliers.meta"
        :meta="suppliers.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      page: 1,
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Entity Type', value: 'entity_type' },
        { text: 'Contact Person', value: 'contact_person.name' },
        { text: 'Date Registered', value: 'registered_at' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      suppliers: 'getSuppliers'
    })
  },

  methods: {
    ...mapActions([
      'setSuppliers'
    ]),

    pageChanged (page) {
      this.page = page
      this.setSuppliers()
    },
  },

  mounted () {
    this.setSuppliers().then(() => {
      this.loading = false
    })
  }
}
</script>